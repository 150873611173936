import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import FmsProjDetails from './fmsProjectDetails';
import { ModelSelect } from 'vue-search-select';
import Organization from '../../../admin/organization';
export default {
  name: 'FmsMasterProject',
  components: {
    ModelSelect,
    FmsProjDetails,
    Organization
  },
  watch: {
    currentPage: function() {
      this.getFmsProjectList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsProjectList();
    }
  },
  data() {
    return {
      orgType: null,
      parent_value_set_id: null,
      openOuModal: false,
      showValueSetModal: false,
      unsubscribe: null,
      showSelectedProjectDetails: false,
      loader: false,
      editMode: false,
      updateMode: false,
      payload: {},
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      location: {
        value: null,
        text: null
      },
      crmProj: {
        value: null,
        text: null
      },
      fmsProjName: '',
      fmsProjCode: '',
      fmsProjId: '',
      projectData: [],
      projectFields: [
        {
          key: 'fms_prj_name',
          label: 'Project'
        },
        {
          key: 'fms_prj_code',
          label: 'Project Code'
        },
        {
          key: 'crm_prj_name',
          label: 'CRM Project'
        },
        {
          key: 'fms_master_prj_name',
          label: 'Master Project'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'lease_location_name',
          label: 'Lease Location'
        },
        {
          key: 'full_address',
          label: 'Billing Address'
        },
        {
          key: 'fms_sector_name',
          label: 'Sector'
        },
        {
          key: 'fms_location_name',
          label: 'FMS Location'
        },
        {
          key: 'liability_ccid_code'
        },
        {
          key: 'debtor_code'
        },
        {
          key: 'fms_pocket_name',
          label: 'Pocket'
        },
        {
          key:'receipt_method_name'
        },
        {
          key:'is_external'
        }
      ],
      defaultValue: {
        value: null,
        text: null
      },
      isExternal:false,
      routeName: this.$router.currentRoute.name
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showSelectedProjectDetails = true;
          this.timeoutEdit = setTimeout(() => {
            this.eventBus.$emit('addCase', !this.editmode);
          }, 0);
        }
        if (actionName === 'download' && !this.showSelectedProjectDetails) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'unitInventory/getFmsProjectList',
            'fms-project',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getFmsProjectList() {
      this.payload = {
        _limit: this.perPage,
        _page: this.currentPage - 1,
        fms_prj_id: this.fmsProjId,
        org_le_id: this.legalEntity.value,
        org_ou_id: this.operatingUnit.value,
        crm_pj_id: this.crmProj.value,
        location_id: this.location.value,
        fms_prj_name: this.fmsProjName,
        fms_prj_code: this.fmsProjCode,
        is_external:this.isExternal
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getFmsProjectList', this.payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.projectData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(err => {
          this.err = err;
          this.loader = false;
        });
    },
    updateList() {
      this.getFmsProjectList();
    },
    rowSelected(rowData) {
      if (this.$router.currentRoute.name === 'fmsProjectView') {
        this.showSelectedProjectDetails = true;
        setTimeout(() => {
          this.eventBus.$emit('showRecordDetails', rowData);
        }, 0);
      } else {
        this.$emit('selectFmsProject', rowData);
      }
    },
    clearFilters() {
      this.legalEntity = this.defaultValue;
      this.operatingUnit = this.defaultValue;
      this.location = this.defaultValue;
      this.crmProj = this.defaultValue;
      this.projectData = [];
      this.parent_value_set_id = null;
      this.fmsProjName = '';
      this.fmsProjCode = '';
      this.totalRows = null;
    },
    hideProjectDetails() {
      this.showSelectedProjectDetails = false;
    },
    openCloseOu(flag, orgType) {
      this.orgType = orgType;
      const dataToGetOrg = {
        orgType: orgType,
        parentLeId: null
      };
      this.$store.dispatch('shared/setUnsetOrgType', dataToGetOrg);
      this.openOuModal = flag;
    },
    selectedOrg(item) {
      if (this.orgType === 'LE') {
        this.legalEntity.value = item.org_id;
        this.legalEntity.text = item.org_name;
      } else if (this.orgType === 'OU') {
        this.operatingUnit.value = item.org_id;
        this.operatingUnit.text = item.org_name;
      } else if (this.orgType === 'LOC') {
        this.location.value = item.org_id;
        this.location.text = item.org_name;
      }
      this.openOuModal = false;
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      if (vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.parent_value_set_id = null;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.operatingUnit = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.location = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT) {
        this.crmProj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.operatingUnit.value) {
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === this.location.value) {
        this.location = this.defaultValue;
      } else if (vsetCode === this.crmProj.value) {
        this.crmProj = this.defaultValue;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
