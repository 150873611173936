export default {
  name: 'BankDetails',
  props: ['receiptId'],
  data() {
    return {
      loader: false,
      bankData: {
        le_name:null,
        project_name:null,
        entity_type:null,
        bank_name:null,
        branch_name:null,
        account_num:null,
        ifsc_code:null,
        receipt_methods_name:null,
      },
      bankDetailsVisible: false
    };
  },
  mounted() {
    this.bankDetailsVisible = true;
    if (this.receiptId) {
      this.getBankData();
    }
  },
  methods: {
    getBankData() {
      const payload = {
        ar_proj_receipt_method_mpng_id: this.receiptId
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/getBankDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.bankData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    closeBankDetails() {
      this.bankDetailsVisible = false;
      this.$emit('closeBankDetails');
    }
  },
  beforeDestroy() {
    this.bankDetailsVisible = false;
  }
};
