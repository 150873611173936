import { ModelSelect } from 'vue-search-select';
import appStrings from '@/app/utility/string.utility';
import Organization from '../../../../admin/organization';
import MasterProject from '../../fmsMasterProject';
import FmsSector from '../../fmsSector';
import FMSPocket from '../../fmsPocket';
import Address from '../../../../admin/address';
import codeCombination from '@/app/components/shared/codeCombination';
import BankDetails from './bankDetails';

export default {
  name: 'FmsProjectDetails',
  components: {
    ModelSelect,
    Organization,
    MasterProject,
    FmsSector,
    FMSPocket,
    Address,
    codeCombination,
    BankDetails
  },
  data() {
    return {
      modalType: null,
      parent_value_set_id: null,
      depVsetParam:null,
      depPrjParam:null,
      openSectorUnitModal: false,
      openMasterProjModal: false,
      vsetCode: null,
      setTimeVsetCode: null,
      showValueSetModal: false,
      orgType: null,
      openOuModal: false,
      showSelectedProjectDetails: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      updateMode: false,
      fmsMasterProjName: null,
      fmsMasterProjId: null,
      fmsPrjId: 0,
      billingAddress: {
        value: null,
        text: null
      },
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      receiptMethod: {
        text: null,
        value: null
      },
      leaseLocation: {
        value: null,
        text: null
      },
      fmsLocation: {
        value: null,
        text: null
      },
      crmProj: {
        value: null,
        text: null
      },
      fmsMasterProj: {
        value: null,
        text: null
      },
      fmsMasterProjCode: null,
      fmsMasterProjData: [],
      fmsSector: {
        value: null,
        text: null
      },
      fmsSectorList: [],
      fmsPocket: {
        value: null,
        text: null
      },
      fmsPocketData: [],
      liabilityAcc: null,
      liabilityAccCcid: null,
      liabilityAccMeaning: null,
      debtor: {
        value: null,
        text: null
      },
      segmentFields: [
        {
          key: 'segment_meaning'
        },
        {
          key: 'segment_code'
        }
      ],
      segmentData: [],
      segmentMeaning: '',
      filterSegmentCode: null,
      isBusy: true,
      showConcatSegmentModal: false,
      defaultValue: {
        value: null,
        text: null
      },
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      },
      showBankForm: false,
      isExternal:false,
    };
  },
  mounted() {
    this.eventBus.$on('showRecordDetails', recordDetails => {
      this.fmsPrjId = recordDetails.fms_prj_id;
      this.fmsSectorCode = recordDetails.fms_prj_code;
      this.fmsSectorId = recordDetails.fms_prj_id;
      this.fmsMasterProjName = recordDetails.fms_prj_name;
      this.fmsMasterProjCode = recordDetails.fms_prj_code;
      this.legalEntity = {
        value: recordDetails.org_le_id,
        text: recordDetails.le_name
      };
      this.operatingUnit = {
        value: recordDetails.org_ou_id,
        text: recordDetails.ou_name
      };
      this.fmsLocation = {
        value: recordDetails.fms_location_id,
        text: recordDetails.fms_location_name
      };
      this.leaseLocation = {
        value: recordDetails.lease_location_id,
        text: recordDetails.lease_location_name
      };
      this.receiptMethod = {
        value: recordDetails.receipt_method_id,
        text: recordDetails.receipt_method_name
      };
      this.crmProj = {
        value: recordDetails.crm_prj_id,
        text: recordDetails.crm_prj_name
      };
      this.fmsMasterProj = {
        value: recordDetails.fms_master_prj_id,
        text: recordDetails.fms_master_prj_name
      };
      this.fmsSector = {
        value: recordDetails.fms_sector_id,
        text: recordDetails.fms_sector_name
      };
      this.fmsPocket = {
        value: recordDetails.fms_pocket_id,
        text: recordDetails.fms_pocket_name
      };
      this.billingAddress = {
        value: recordDetails.billing_add_id,
        text: recordDetails.full_address
      };
      this.debtor = {
        value: recordDetails.debtor_code,
        text: recordDetails.debtor_meaning
      };
      this.liabilityAcc = recordDetails.liability_ccid_code;
      this.liabilityAccCcid = recordDetails.liability_ccid;
      this.liabilityAccMeaning = recordDetails.liability_ccid_meaning;
      this.isExternal=recordDetails.is_external;
    });
    this.eventBus.$off('addCase');
    this.eventBus.$on('addCase', editMode => {
      this.updateMode = editMode;
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          this.addAndEditFmsProject();
        }
        if (actionName === 'update') {
          this.updateMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.fmsPrjId });
        }
      }
    });
  },
  methods: {
    addAndEditFmsProject() {
      const payload = {
        billing_add_id: this.billingAddress.value,
        crm_prj_id: this.crmProj.value,
        fms_master_prj_id: this.fmsMasterProj.value,
        fms_pocket_id: this.fmsPocket.value,
        fms_prj_code: this.fmsMasterProjCode,
        fms_prj_id: this.fmsPrjId,
        fms_prj_name: this.fmsMasterProjName,
        fms_sector_id: this.fmsSector.value,
        le_id: this.legalEntity.value,
        lease_location_id: this.leaseLocation.value,
        fms_location_id: this.fmsLocation.value,
        ou_id: this.operatingUnit.value,
        debtor_code: this.debtor.value,
        liability_ccid: this.liabilityAccCcid,
        ar_proj_receipt_method_mpng_id: this.receiptMethod.value,
        is_external:this.isExternal
      };
      this.loader = true;
      this.$store
        .dispatch('unitInventory/addAndEditFmsProject', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.$emit('updateList');
            this.updateMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openCloseOu(flag, orgType) {
      this.orgType = orgType;
      const dataToGetOrg = {
        orgType: orgType,
        parentLeId: null
      };
      this.$store.dispatch('shared/setUnsetOrgType', dataToGetOrg);
      this.openOuModal = flag;
    },
    selectedOrg(item) {
      if (this.orgType === 'LE') {
        this.legalEntity.value = item.org_id;
        this.legalEntity.text = item.org_name;
      } else if (this.orgType === 'OU') {
        this.operatingUnit.value = item.org_id;
        this.operatingUnit.text = item.org_name;
      } else if (this.orgType === 'LOC') {
        this.leaseLocation.value = item.org_id;
        this.leaseLocation.text = item.org_name;
      }
      this.openOuModal = false;
    },
    openValueSetModal(vsetCode, locationType) {
      this.vsetCode = vsetCode;
      this.locationType = locationType;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.depVsetParam=null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      } else  if (this.vsetCode === appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET) {
        this.parent_value_set_id = this.fmsPrjId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.operatingUnit = this.defaultValue;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        if (this.locationType === 'leaseLocation') {
          this.leaseLocation = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        } else if (this.locationType === 'fmsLocation') {
          this.fmsLocation = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
        }
      }  else if (this.vsetCode === appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET) {
        this.receiptMethod = {
          value: item.ar_proj_receipt_method_mpng_id,
          text: item.receipt_methods_name
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.VATIKA_GL_Accounts) {
        this.debtor = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT) {
        this.crmProj = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
      this.showValueSetModal = false;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHiderMasterModal(flag) {
      this.openMasterProjModal = flag;
    },
    selectedMasterProj(item) {
      this.fmsMasterProj.value = item.fms_master_prj_id;
      this.fmsMasterProj.text = item.fms_master_prj_name;
      this.showHiderMasterModal(false);
    },
    showHideSectorUnitModal(flag, modalType) {
      this.modalType = modalType;
      this.openSectorUnitModal = flag;
    },
    selectFmsSector(item) {
      this.fmsSector.value = item.fms_sector_id;
      this.fmsSector.text = item.fms_sector_name;
      this.openSectorUnitModal = false;
    },
    selectedFmsPocket(item) {
      this.fmsPocket.value = item.fms_pocket_id;
      this.fmsPocket.text = item.fms_pocket_name;
      this.openSectorUnitModal = false;
    },
    getAddressObj(item) {
      this.billingAddress.value = item.address_id;
      this.billingAddress.text = item.completeAddress;
      this.openSectorUnitModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = this.defaultValue;
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.operatingUnit = this.defaultValue;
      } else if (vsetCode === 'leaseLocation') {
        this.leaseLocation = this.defaultValue;
      } else if (vsetCode === 'fmsLocation') {
        this.fmsLocation = this.defaultValue;
      } else if (vsetCode === 'MASTER_PROJECT') {
        this.fmsMasterProj = this.defaultValue;
      } else if (vsetCode === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT) {
        this.crmProj = this.defaultValue;
      } else if (vsetCode === 'SECTOR') {
        this.fmsSector = this.defaultValue;
      } else if (vsetCode === 'POCKET') {
        this.fmsPocket = this.defaultValue;
      } else if (vsetCode === this.liabilityAcc) {
        this.liabilityAcc = null;
        this.liabilityAccCcid = null;
        this.liabilityAccMeaning = null;
      } else if (vsetCode === this.debtor.value) {
        this.debtor = {
          value: null,
          text:null
        };
      } else if (vsetCode === appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET) {
        this.receiptMethod = this.defaultValue;
      }
    },
    showHideCodeCombinationModal(flag, name) {
      this.accountType = name;
      if (flag) {
        if (!this.legalEntity.value) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.fmsMasterProjCode) {
          return alert(appStrings.DEPENDENTVSETMSG.PROJECTMSG);
        }
        this.combinationDetails.le_id = this.legalEntity.value;
        this.combinationDetails.project_code = this.fmsMasterProjCode;
      }
      this.showCodeCombinationModal = flag;
    },
    showBankDetailsForm() {
      this.showBankForm = true;
    },
    closeBankDetails() {
      this.showBankForm = false;
    },
    selectedSegment(item) {
      if (this.accountType === 'liability') {
        this.liabilityAccCcid = item.ccid;
        this.liabilityAcc = item.segment_code;
        this.liabilityAccMeaning = item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
    this.eventBus.$off('showRecordDetails');
  }
};
